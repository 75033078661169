<template>
    <router-view />
</template>

<script>
    export default {
        name: 'transport',
        
        metaInfo () {
            return { title: 'Price & Leadtime' }
        },

        data: () => ({
        
        }),

        created(){},

        computed: {},

        methods: {}
    }
</script>